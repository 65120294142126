/* Common styles */
.free-tree-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 1%;
}

.family-tree-container {
  height: 100%; 
  position: relative;
}

#ped svg {
  width: 100%;
  overflow-y: auto;
}

.free-tree-container {
  height: 100%;
  width: 100%;
}

#pedigree_history {
  display: flex;
  justify-content: end;
}

#pedigree_history span i {
  margin: 5px;
  cursor: pointer;
  background-color: transparent;
}

#ped::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

#ped.no-before::before {
  content: none; 
}

.family-tree-container.edit-mode {
  border: 2px solid #eb6c67;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  border-radius: 5px; 
  overflow-y: auto;
}

.preview-img {
  height: 720px;
  width: 100%;
}

[id$="-ped"] svg {
  width: 100%;
}

[id$="-pedigree_history"] {
  display: none;
}

[id$="-pedigree_history"] span i {
  margin: 5px;
  cursor: pointer;
  background-color: transparent;
}

[id$="-ped"]::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

[id$="-ped"].no-before::before {
  content: none;
}

[id$="-ped"].edit-mode {
  border: 2px solid #eb6c67;
  padding: 10px;
  border-radius: 5px;
}

