.outlined-input-container {
    position: relative;
    margin: 10px 0;
    width: 100%;
  }
  .outlined-input-container:focus-within .outlined-label{
    position: absolute;
    top: 14px;
    left: 12px;
    background-color: white !important;
    padding: 0 5px;
    color: #9f9b9b;
    font-size: 14px;
    transition: all 0.2s ease;
    pointer-events: none;
    z-index: 1;
  }
  .outlined-input-container:hover .outlined-label {
    z-index: 10; /* Bring the hover effects forward */
    color: #565252;
  }
  /* Input field hover & focus effect */
  .outlined-input-container:focus-within .outlined-label{
    position: absolute;
    top: -8px;
    left: 12px;
    background-color: white !important;
    padding: 0 5px;
    color: #565252;
    font-size: 12px;
    transition: all 0.2s ease;
    pointer-events: none;
    z-index: 1;
  }
  
  /* Common Input Style */
  .outlined-input {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #9f9b9b;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
    transition: border-color 0.2s ease;
    background-color: white;
    color: #000;
  }
  
  .outlined-input:focus {
    border-color: #FD6262;
  }
  
  /* Ant Design Password Input Customization */
  .outlined-input .ant-input {
    padding: 0; /* Remove extra padding for consistency */
    border: none; /* Remove default Ant Design border */
    outline: none;
    box-shadow: none; /* Remove any focus shadow */
  }
  
  .outlined-input-container:focus-within .outlined-input {
    border-color: #FD6262; /* Focus effect */
  }
  
  /* Floating Placeholder */
  .outlined-label {
    position: absolute;
    top: 14px;
    left: 12px;
    background-color: white !important;
    padding: 0 5px;
    color: #9f9b9b;
    font-size: 14px;
    transition: all 0.2s ease;
    pointer-events: none;
  }
  
  .focused .outlined-label,
  .outlined-input-container:focus-within .outlined-label {
    top: -8px;
    font-size: 12px;
    color: #565252;
  }

.outlined-input-container .ant-input-password input:focus ~ .outlined-label,
.outlined-input-container .ant-input-password input:not(:placeholder-shown) ~ .outlined-label {
  top: -8px;
  font-size: 12px;
  color: #565252;
}