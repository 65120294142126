.custom-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .custom-alert-box {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    width: 600px;
    max-width: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #000;
  }
  
  .custom-alert-box h2 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
  }
  
  .custom-alert-box p {
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .custom-alert-actions {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
  }
  
  .custom-alert-cancel,
  .custom-alert-confirm {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .custom-alert-cancel {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
  }
  
  .custom-alert-cancel:hover {
    border: 1px solid #000;
  }
  
  .custom-alert-confirm {
    background-color: #EB6C67;
    color: #fff;
  }
  