body {
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}

.icon-display-hidden {
  display: none;
}

#pedigree_history {
  /* color: white; */
  margin-bottom: 2px;
  display: flex;
  justify-content: end;
}

.custom-table .ant-table {
  background-color: #2e2c32 !important;
  border: 2px solid #525252 !important;
  border-radius: 4px 4px 4px 4px !important; /* Rounds only the bottom corners */
}
.ant-pagination .ant-pagination-item {
  background-color: #2e2c32 !important;
  color: #fd6262 !important;
}
.ant-pagination .ant-pagination-item a {
  background-color: black !important;
  color: #fd6262 !important;
}
.ant-pagination .ant-pagination-jump-next {
  background-color: black !important;
  margin-inline-end: 0px;
  border-radius: 0px !important;
}
.ant-pagination .ant-pagination-jump-prev {
  background-color: black !important;
  margin-inline-end: 0px;
  border-radius: 0px !important;
}
.ant-pagination .ant-pagination-next {
  border-radius: 0px !important;
}
.ant-pagination .ant-pagination-prev {
  
  margin-inline-end: 0px;
  border-radius: 0px !important;
}

.ant-pagination {
  margin-top: 1rem;
}
/* .ant-table-cell>tr>th::before{
  background-color: #2e2c32 !important; 
} */
 .ant-table-cell{
  background-color: #2e2c32 !important;
 }
.custom-table .ant-table-thead > tr > th {
  background-color: #2e2c32 !important;
  /* Header background color */
  border-bottom: none !important;
}
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: #2e2c32 !important;
}
.custom-table .ant-table-tbody > tr > td {
  border-bottom: none !important; /* Remove row borders */

  color: #fff !important;
}

.custom-table .ant-table-tbody > tr:hover > td {
  background-color: #4e4b52 !important; /* Row hover color */

  color: #fff !important;
}

.custom-table .ant-pagination {
  border-top: 1px solid #2e2c32 !important; /* Border above pagination */

  background-color: #2e2c32;
}

.custom-table .ant-table{
  overflow-y: auto !important;
  display: block !important;
}
@media (max-height: 600px) { 
  .custom-table .ant-table {
    max-height: calc(75vh - 60px) !important; 
  }
}

@media (min-height: 601px) and (max-height: 800px) {
  .custom-table .ant-table {
    max-height: calc(75vh - 50px) !important;
  }
}

@media (min-height: 801px) {
  .custom-table .ant-table {
    max-height: calc(75vh - 10px) !important;
  }
}
.custom-table .ant-table-wrapper .ant-table-thead > tr > td::before {
  background-color: #fd6262 !important;
  content: none !important;
}
.ant-spin-nested-loading {
  background-color: transparent !important;
}

/* Optional: Additional styling for the scrollbar */

:where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper
  .ant-table::-webkit-scrollbar {
  width: 8px;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper
  .ant-table::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25); /* Color of the scrollbar thumb */

  border-radius: 4px;
}
.ant-spin-nested-loading .ant-spin-container {
  position: relative;
  transition: opacity 0s !important;
}


:where(.css-dev-only-do-not-override-ni1kz0).ant-table-wrapper
  .ant-table::-webkit-scrollbar-track {
  background-color: #f0f2f5; /* Color of the scrollbar track */
}
/* Pagination item color */
.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-disabled .ant-pagination-item-link {
  color: #fff !important;
  background-color: #2d2d2d; /* Dark background to match second image */
  border: none;
}
 .ant-progress-text {
  color: white !important;
}

/* Hover styles */
.ant-pagination-item:hover,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #eb6c67 !important;

  /* Darker background on hover */
}
.ant-pagination-item-link {
  background-color: #d3d3d31f !important;
  
}

/* Ellipsis and icon color */
.ant-pagination-item-ellipsis,
.ant-pagination-item-ellipsis:hover,
.ant-pagination-item-link-icon {
  color: #fff !important;
}
.ant-pagination-prev .ant-pagination-disabled {
  background-color: #17151b !important;
}
/* Active item styles */
.ant-pagination-item-active a {
  font-size: 18px !important;
}
.ant-pagination-item-active {
  color: white !important;
  /* Set border color to white */
  font-size: 18px !important;
  line-height: 36kpx !important;
  background-color: #eb6c67 !important; /* Highlight active background */
  font-weight: bold; /* Make font bold */
}

.custom-table .ant-pagination-item,
.custom-table .ant-pagination-prev .ant-pagination-item-link,
.custom-table .ant-pagination-next .ant-pagination-item-link {
  /* Adjust height */
  line-height: 32px !important; /* Center the text vertically */
  font-size: 14px !important; /* Smaller font */
  padding: 0 !important; /* Remove extra padding */
  margin: 0 !important; /* Adjust margin for spacing */
  border-radius: 4px !important;
  border-color: 1px solid #eb6c67;
  /* Round the corners slightly */
}
.custom-dropdown .ant-dropdown-menu {
  margin-top: 22px; /* Adjusts dropdown position closer to the username */
  border-radius: 8px;
  background-color: #000000;; /* Rounded corners */
}

.custom-dropdown .ant-dropdown-menu-item {
  font-size: 14px;
  color: white !important;
  text-align: left; /* Align text to the left for a cleaner look */
  padding: 8px 16px; /* Adds some padding for better click area */
}

.custom-dropdown .ant-dropdown-menu-item:hover {
  background-color: #0d0a0a; /* Subtle hover effect */
}
.custom-dropdown .ant-dropdown .ant-dropdown-menu{
  border-radius: 0px !important;
}

@media (min-width: 1024px) {
  .lg\:h-screen {
      height: 91vh;
  }
}