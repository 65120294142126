/* xyflow theme files. Delete these to start from our base */

.react-flow {
    /* Custom Variables */
    --xy-theme-selected: #F57DBD;
    --xy-theme-hover: #C5C5C5;
    --xy-theme-edge-hover: black;
    --xy-theme-color-focus: #E8E8E8;

    /* Built-in Variables see https://reactflow.dev/learn/customization/theming */
    --xy-node-border-default: 1px solid #EDEDED;
  
    --xy-node-boxshadow-default: 
      0px 3.54px 4.55px 0px #00000005, 
      0px 3.54px 4.55px 0px #0000000D, 
      0px 0.51px 1.01px 0px #0000001A;
  
    --xy-node-border-radius-default: 8px;
  
    --xy-handle-background-color-default: #ffffff;
    --xy-handle-border-color-default: #AAAAAA;
  
    --xy-edge-label-color-default: #505050;
}

.react-flow.dark { 
    --xy-node-boxshadow-default: 
    0px 3.54px 4.55px 0px rgba(255, 255, 255, 0.05), /* light shadow */
    0px 3.54px 4.55px 0px rgba(255, 255, 255, 0.13), /* medium shadow */
    0px 0.51px 1.01px 0px rgba(255, 255, 255, 0.2);  /* smallest shadow */  
    --xy-theme-color-focus: #535353;
}

/* Customizing Default Theming */

.react-flow__node {

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    flex-direction: column;
}

.react-flow__node.selectable:focus {
    box-shadow: 0px 0px 0px 4px var(--xy-theme-color-focus);
    border-color: #d9d9d9;
}

.react-flow__node.selectable:focus:active {
    box-shadow: var(--xy-node-boxshadow-default);
}

.react-flow__node.selectable:hover, 
.react-flow__node.draggable:hover {
    border-color: var(--xy-theme-hover);
}

.react-flow__node.selectable.selected {
    border-color: var(--xy-theme-selected);
    box-shadow: var(--xy-node-boxshadow-default);
}

.react-flow__edge.selectable:hover .react-flow__edge-path,
.react-flow__edge.selectable.selected .react-flow__edge-path {
    stroke: var(--xy-theme-edge-hover);
}

.react-flow__handle {
    background-color: var(--xy-handle-background-color-default);
}

.react-flow__handle.connectionindicator:hover {
    pointer-events: all;
    border-color: var(--xy-theme-edge-hover);
}

.react-flow__handle.connectionindicator:focus , 
.react-flow__handle.connectingfrom,
.react-flow__handle.connectingto {
    border-color: var(--xy-theme-edge-hover);
}

.react-flow__node-resizer {
    border-radius: 0;
    border: none;
}

.react-flow__resize-control.handle {
    background-color: #ffffff;
    border-color: #9E86ED;
    border-radius: 0;
    width: 5px;
    height: 5px;
}